class FlashMessage {
  constructor() {
    this.closeButton = document.querySelector(".js-close-button");
    this.flash = document.querySelector(".js-flash");
    this.bind();
  }

  hideFlash() {
    this.flash.classList.add("js-is-hidden");
  }

  bind() {
    if (!this.flash) {
      return;
    }
    if (this.closeButton) {
      this.closeButton.addEventListener("click", () => {
        this.hideFlash();
      });
    }
    if (this.flash.classList.contains("js-notice")) {
      setTimeout(() => {
        this.hideFlash();
      }, 5000);
    }
  }
}

export default FlashMessage;
