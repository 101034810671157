class SeeMoreGuests {
  constructor() {
    this.elems = Array.from(document.querySelectorAll('.itinerary__guests-more'));
    this.bind();
  }

  bind() {
    this.elems.forEach(elem => {
      elem.addEventListener('click', (evt) => {
        evt.preventDefault();
        const targetElem = document.querySelector('.itinerary__guests');
        if (!targetElem) return;

        targetElem.classList.toggle('itinerary__guests_ellipsis');
        if (targetElem.classList.contains('itinerary__guests_ellipsis')) {
          elem.textContent = 'See more';
        } else {
          elem.textContent = 'See less';
        }
      });
    });
  }
}

export default SeeMoreGuests;
