class Dropdown {
  constructor() {
    this.dropdownButton = document.querySelector(".js-dropdown-button");
    this.dropdown = document.querySelector(".js-dropdown");
    this.bind();
  }

  toggleDropdown() {
    this.dropdown.classList.toggle("js-is-hidden");
  }

  bind() {
    if (!this.dropdownButton) {
      return;
    }
    this.dropdownButton.addEventListener("click", () => {
      this.toggleDropdown();
    });
  }
}

export default Dropdown;
