const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const animateScroll = async (parent, child, totalDuration) => {
  const startTime = Date.now();
  const endTime = startTime + totalDuration;
  const startY = parent.scrollTop;
  const endY = child.offsetTop;
  const gradient = (endY - startY) / (endTime - startTime);

  let currentTime = startTime;
  while (currentTime < endTime) {
    parent.scrollTop = gradient * (currentTime - startTime) + startY;

    await sleep(33); // 30 FPS
    currentTime = Date.now();
  }
};

class HashtagScroll {
  constructor() {
    this.tags = Array.from(document.querySelectorAll('.hero__hashtag'));
    this.container = document.querySelector('.hero__hashtags');
    this.filter = document.querySelector('.hero__hashtags-filter');
    this.activeIndex = 0;

    this.run();
  }

  async run() {
    if (!this.container) return;

    for (let n = 0; ; n += 1) {
      if (n >= this.tags.length) {
        n = 0;
        this.activeIndex = n;
        this.container.scrollTop = 0;
        continue;
      }

      this.activeIndex = n;
      this.updateStyles();
      await animateScroll(this.container, this.tags[n], 320);

      await sleep(1600);
    }
  }

  updateStyles() {
    this.tags.map(t => t.classList.remove('hero__hashtag_active'));
    this.tags[this.activeIndex].classList.add('hero__hashtag_active');
    this.updateHeight();
  }

  getActiveTag() {
    return this.tags[this.activeIndex];
  }

  updateHeight() {
    this.getActiveTag().style.height = 'auto';
    const height = this.getActiveTag().clientHeight + 10;
    this.container.style.height = `${height}px`;
    this.filter.style.height = `${height}px`;
    this.getActiveTag().style.height = `${height}px`;
  }
}

export default HashtagScroll;
