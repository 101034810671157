class ItinerarySlider {
  constructor() {
    this.mediaSourcesLabel = document.querySelector(".js-itinerary-media-sources-label");
    this.mediaSourcesList = document.querySelector(".js-itinerary-media-sources-list");
    this.bind();
  }

  bind() {
    if (!this.mediaSourcesList) { return; }

    this.mediaSourcesLabel.addEventListener("click", () => {
      const listHeight = this.mediaSourcesList.scrollHeight;

      if (!this.mediaSourcesList.style.height) {
        this.mediaSourcesList.style.height = `${listHeight}px`;
      } else {
        this.mediaSourcesList.style.height = "";
      }
    });
  }
}

export default ItinerarySlider;
