class ForgotPassword {
  constructor() {
    this.link = document.querySelector('.sign-in__forgot-password-link');
    this.bind();
  }

  bind() {
    if (!this.link) return;

    this.link.addEventListener('click', (evt) => {
      evt.preventDefault();

      document.forms[0].forgot_password.value = 1;
      document.forms[0].submit();
    });
  }
}

export default ForgotPassword;
