
/* global MicroModal */
export default class DraftItinerary {
  constructor() {
    this.button = document.querySelector('.itinerary__share-draft-trip');
    if (!this.button) return;

    this.bind();
  }

  bind() {
    this.button.addEventListener('click', () => {
      MicroModal.show('share-draft-trip-modal');
    });
  }
}
