class VideoOverlay {
  constructor() {
    this.videoOverlay = document.querySelector(".js-hero-video-overlay");
    this.videoWrap = document.querySelector(".js-hero-video-wrap");
    this.bind();
  }

  addVideo() {
    let videoId = this.videoWrap.classList.contains('js-hero-video-ja') ? 'smVn7fmx7Ck' : 'wBmuvMxXJkI';

    const videoElement = `
    <iframe class="hero__video"
      src="https://www.youtube-nocookie.com/embed/${videoId}?modestbranding=1&rel=0&autoplay=1"
      frameborder="0"
      allow="accelerometer; encrypted-media; autoplay; gyroscope; picture-in-picture"
      allowfullscreen>
    </iframe>`;
    this.videoWrap.insertAdjacentHTML("afterbegin", videoElement);
  }

  hideVideoOverlay() {
    this.videoOverlay.classList.add("hero__video-overlay--hidden");
  }

  bind() {
    if (!this.videoOverlay) {
      return;
    }
    this.videoOverlay.addEventListener("click", () => {
      this.addVideo();
      this.hideVideoOverlay();
    });
  }
}

export default VideoOverlay;
