class ItineraryStickyCta {
  constructor() {
    this.pricePanel = document.querySelector(".js-itinerary-price-panel");
    this.stickyCta = document.querySelector(".js-itinerary-sticky-cta");
    this.bind();
  }

  showStickyCta() {
    this.stickyCta.classList.remove("js-is-hidden");
  }

  hideStickyCta() {
    this.stickyCta.classList.add("js-is-hidden");
  }

  bind() {
    if (!this.pricePanel || !this.stickyCta) {
      return;
    } else {
      window.addEventListener("scroll", () => {
        const yEndPricePanel = this.pricePanel.getBoundingClientRect().bottom;
        yEndPricePanel <= 0 ? this.showStickyCta() : this.hideStickyCta();
      });
    }
  }
}

export default ItineraryStickyCta;
