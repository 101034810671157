class MobileDropdown {
  constructor() {
    this.openDropdownButton = document.querySelector(".js-mobile-dropdown-open-button");
    this.closeDropdownButton = document.querySelector(".js-mobile-dropdown-close-button");
    this.Dropdown = document.querySelector(".js-mobile-dropdown");
    this.bind();
  }

  openDropdown() {
    this.closeDropdownButton.classList.remove("js-is-hidden");
    this.Dropdown.classList.remove("js-is-hidden");
    this.openDropdownButton.classList.add("js-is-hidden");
  }

  closeDropdown() {
    this.closeDropdownButton.classList.add("js-is-hidden");
    this.Dropdown.classList.add("js-is-hidden");
    this.openDropdownButton.classList.remove("js-is-hidden");
  }

  bind() {
    if (!this.Dropdown) {
      return;
    } else {
      this.openDropdownButton.addEventListener("click", () => {
        this.openDropdown();
      });
      this.closeDropdownButton.addEventListener("click", () => {
        this.closeDropdown();
      });
    }
  }
}

export default MobileDropdown;
