class SignInHelper {
  static createInput(form, name, value) {
    const input = document.createElement('input');
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    input.setAttribute('type', 'hidden');
    form.appendChild(input, null);
  }

  constructor() {
    if (!/\w{2}\/users\/sign_in/.test(location.pathname)) return;

    const checkoutData = this.getCheckoutData();
    if (!checkoutData) return;

    const form = document.querySelector('#new_user');
    if (!form) return;

    SignInHelper.createInput(form, 'guest_id', checkoutData.guest.id);

    this.bind(form);
  }

  getCheckoutData() {
    if ('checkoutData' in window.sessionStorage) {
      try {
        return JSON.parse(window.sessionStorage.getItem('checkoutData'));
      } catch {
        return null;
      }
    }
    return null;
  }

  bind(form) {
    form.addEventListener('submit', () => {
      window.sessionStorage.removeItem('checkoutData');
    });
  }
}

export default SignInHelper;
