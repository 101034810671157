class Accordion {
  constructor() {
    this.accordions = Array.from(document.querySelectorAll(".accordion"));
    this.bind();
  }

  bind() {
    this.accordions.forEach(accordion => {
      const toggle = accordion.querySelector('.accordion-toggle');
      if (!toggle) return;

      toggle.addEventListener('click', () => {
        accordion.classList.toggle('accordion__expanded');
      });
    });
  }
}

export default Accordion;
