class ItineraryTocNav {
  constructor() {
    this.tocNav = document.querySelector(".js-itinerary-toc-nav");
    this.bind();
  }

  bind() {
    if (!this.tocNav) { return; }

    window.addEventListener("DOMContentLoaded", () => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          if (entry.intersectionRatio > 0) {
            const link = this.tocNav.querySelector(`.js-itinerary-toc-nav-link[href="#${id}"]`);
            if (link) {
              link.scrollIntoView();
              link.classList.add("active");
            }
          } else {
            const link = this.tocNav.querySelector(`.js-itinerary-toc-nav-link[href="#${id}"]`);
            if (link) {
              link.classList.remove("active");
            }
          }
        });
      });

      document.querySelectorAll("div[id^='toc']").forEach((div) => {
        observer.observe(div);
      });
    });
  }
}

export default ItineraryTocNav;
