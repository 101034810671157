class DropdownMenu {
  constructor() {
    this.menus = Array.from(document.querySelectorAll(".dropdown-menu"));
    this.bind();
  }

  closeOthers() {
    window.addEventListener('click', (event) => {
      if (!event.target.closest('.dropdown-menu')) {
        this.menus.forEach(menu => {
          const list = menu.querySelector('.dropdown-menu-list');
          if (!list) return;

          list.classList.remove('dropdown-menu-show');
        });
      }
    });
  }

  bind() {
    this.menus.forEach(menu => {
      const button = menu.querySelector('.dropdown-menu-button');
      if (!button) return;

      button.addEventListener('click', (event) => {
        event.stopPropagation();
        const list = menu.querySelector('.dropdown-menu-list');
        if (!list) return;

        list.classList.toggle('dropdown-menu-show');
      });
    });

    this.closeOthers();
  }
}

export default DropdownMenu;
