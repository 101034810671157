class SimpleFormVisiblePassword {
  constructor() {
    this.passwordField = document.querySelector('.visible-password-field');
    if (!this.passwordField) return;

    this.form = this.passwordField.closest('form');
    this.input = this.passwordField.querySelector('input');
    this.icon = this.passwordField.querySelector('.icon');
    this.passwordConfirmation = this.form.querySelector('input[type=hidden][name*=password_confirmation]');
    this.bind();
  }

  bind() {
    this.icon.addEventListener('click', () => {
      if (this.input.type === 'password') {
        this.input.type = 'text';
      } else {
        this.input.type = 'password';
      }
    });

    this.form.addEventListener('submit', (evt) => {
      if (!this.passwordConfirmation) return;

      evt.preventDefault();
      this.passwordConfirmation.value = this.input.value;
      evt.target.submit();
    });
  }
}

export default SimpleFormVisiblePassword;
