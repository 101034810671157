import api from '@/utils/api.js';

class FacebookHelper {
  constructor() {
    this.buttons = Array.from(document.querySelectorAll(".link-facebook"));
    this.bind();
  }

  login(button) {
    FB.login(async response => { // eslint-disable-line no-undef
      const { status, authResponse } = response;
      if (status !== 'connected') {
        console.error(response);
        return;
      }

      const { accessToken, expiresIn } = authResponse;

      this.showLoading(button);
      await this.updateUser({
        accessToken, expiredAt: new Date(Date.now() + expiresIn * 1000)
      });
    }, { scope: 'email,public_profile' });
  }

  async updateUser({ accessToken, expiredAt }) {
    try {
      await api.put('/en/users.json', {
        user: {
          facebook_profile: {
            access_token: accessToken,
            expired_at: expiredAt
          }
        }
      });
      location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  showLoading(button) {
    const container = button.closest('.link-social-network');
    if (!container) return;

    const loader = container.querySelector('.icon-diagram-dash-circle');
    if (!loader) return;

    loader.classList.toggle('icon-diagram-dash-circle__hide');
  }

  bind() {
    this.buttons.forEach(button => {
      button.addEventListener('click', () => {
        this.login(button);
      });
    });
  }
}

export default FacebookHelper;
