class OperatorReview {
  constructor() {
    this.elems = Array.from(document.querySelectorAll('.itinerary__operator-review-read-more'));
    this.bind();
  }

  bind() {
    this.elems.forEach(elem => {
      elem.addEventListener('click', (evt) => {
        evt.preventDefault();
        const { parentElement: parent } = elem;
        const targetElem = parent.querySelector('.itinerary__operator-review-message');
        if (!targetElem) return;

        targetElem.classList.toggle('itinerary__operator-review-message_ellipsis');
        if (targetElem.classList.contains('itinerary__operator-review-message_ellipsis')) {
          elem.textContent = 'Read more';
        } else {
          elem.textContent = 'Read less';
        }
      });
    });
  }
}

export default OperatorReview;
