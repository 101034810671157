import Siema from "siema";

class ItineraryCarousel {
  constructor() {
    this.carousel = document.querySelector(".js-itinerary-carousel");
    this.prev = document.querySelector(".js-itinerary-carousel-prev");
    this.next = document.querySelector(".js-itinerary-carousel-next");
    this.bind();
  }

  bind() {
    if (!this.carousel) { return; }

    const itineraryCarousel = new Siema({
      selector: this.carousel,
      loop: true,
    });

    this.prev.addEventListener("click", () => itineraryCarousel.prev());
    this.next.addEventListener("click", () => itineraryCarousel.next());
  }
}

export default ItineraryCarousel;
